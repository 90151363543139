import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { QRCodeSVG } from "qrcode.react";

const styles = {
  timeline: {
    width: "90%",
    margin: "20px auto",
    background: "#333",
    padding: "20px",
    color: "#fff",
    borderRadius: "8px",
  },
  event: {
    borderLeft: "4px dotted #898989",
    margin: "20px 0",
    paddingLeft: "20px",
    position: "relative",
    paddingBottom: "10px",
  },
  label: {
    fontWeight: "600",
    color: "#bbb",
    marginBottom: "4px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    borderBottom: "1px solid #444",
    paddingBottom: "15px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  description: {
    color: "#ccc",
    fontWeight: "400",
    marginTop: "10px",
    fontSize: "0.9rem",
    lineHeight: "1.4",
  },
  timeAndAirport: {
    fontSize: "1.1rem",
    fontWeight: "500",
    color: "#fff",
  },
  duration: {
    fontSize: "0.9rem",
    color: "#ccc",
    fontStyle: "italic",
  },
  dateHeader: {
    fontSize: "1.2rem",
    color: "#ddd",
    margin: "10px 0 0",
    paddingTop: "10px",
  },
  flightDetails: {
    fontSize: "0.9rem",
    color: "#bbb",
    fontStyle: "italic",
    marginTop: "5px",
  },
  button: {
    margin: "20px auto",
    display: "block",
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#007BFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

const Home = () => {
  const [destination, setDestination] = useState("");
  const [tripId, setTripId] = useState("");
  const [pnr, setPnr] = useState("");
  const [flightData, setFlightData] = useState([]);
  const [errors, setErrors] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const pnrParam = urlParams.get("pnr") ?? "";
        const tripidParam = urlParams.get("tripid") ?? "";
        const response = await fetch(
          `https://n8n.arbatravel.com/webhook/customer13414?tripid=${tripidParam}&pnr=${pnrParam}`
        );
        const data = await response.json();
        setDestination(data[0].destination);
        setTripId(data[0].tripid);
        setPnr(data[0].pnr);
        setFlightData(data[0].details);
        console.log(data);
      } catch (error) {
        console.error("Error fetching the data:", error);
        setErrors("The flight data is currently unavailable.");
      }
    };
    fetchData();
  }, []);

  const downloadPdf = () => {
    const input = document.getElementById("timeline");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = 120;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const x = (pdf.internal.pageSize.getWidth() - pdfWidth) / 2; // Calculate the x position to center the image
      const y = (pdf.internal.pageSize.getHeight() - pdfHeight) / 2; // Calculate the y position to center the image
      pdf.addImage(imgData, "PNG", x, y, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.match(/.{1,2}/g);
    let period = "AM";
    let formattedHours = parseInt(hours, 10);
    if (formattedHours >= 12) {
      period = "PM";
      if (formattedHours > 12) {
        formattedHours -= 12;
      }
    }
    return `${formattedHours}:${minutes} ${period}`;
  };

  const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/");
    return new Date(year, month - 1, day);
  };

  const formatDisplayDate = (date) => {
    return formatDate(date).toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const renderFlightDetails = () => {
    let lastDate = "";
    let layover_depart_date = "";
    let layover_depart_time = "";
    let layover_offset_depart = 0;

    let layover_return_date = "";
    let layover_return_time = "";
    let layover_offset_return = 0;

    // Depart transit layover, filter by depart and transit
    layover_depart_date = flightData.filter(
      (event) => event.type === "depart" && event.transit === true
    )[0]?.date || "";
    layover_depart_time = flightData.filter(
      (event) => event.type === "depart" && event.transit === true
    )[0]?.time || "";
    layover_offset_depart = flightData.filter(
      (event) => event.type === "depart" && event.transit === true
    )[0]?.offset || 0;

    // Arrival transit layover, filter by arrival and transit
    layover_return_date = flightData.filter(
      (event) => event.type === "return" && event.transit === true
    )[0]?.date || "";
    layover_return_time = flightData.filter(
      (event) => event.type === "return" && event.transit === true
    )[0]?.time || "";
    layover_offset_return = flightData.filter(
      (event) => event.type === "return" && event.transit === true
    )[0]?.offset || 0;

    console.log(
      layover_depart_date,
      layover_depart_time,
      layover_return_date,
      layover_return_time
    );
    return flightData.map((event, index) => {
      const displayDate = formatDisplayDate(event.date);
      const showDateHeader = lastDate !== displayDate;
      lastDate = displayDate;

      return (
        <>
          {showDateHeader && <div style={styles.dateHeader}>{displayDate}</div>}

          <div
            key={index}
            style={{ ...styles.event, borderLeft: "1px dotted grey" }}
          >
            <div style={styles.timeAndAirport}>
              {formatTime(event.time)} • {event.airport}
            </div>
            <div style={styles.duration}>
              {/* Travel time:{" "}
              {calculateDuration(
                event.date,
                event.time,
                event.arrival_date,
                event.arrival_time,
                event.offset,
                event.offset_arrival
              )} */}
            </div>
            <div style={styles.timeAndAirport}>
              {formatTime(event.arrival_time)} • {event.airport_arrival}
            </div>
          </div>
          <div style={styles.flightDetails}>
            {event.airline} • Flight Code: {event.flight_code}
          </div>
          <div style={styles.flightDetails}>
           *The time mentioned refers to the Local Time of the destination.
          </div>
          {event.type == "depart" &&
            layover_depart_date.length > 0 &&
            event.transit == false && (
              <hr style={{ borderTop: "1px dotted grey" }} />
            )}
          {event.type == "depart" &&
            layover_depart_date.length > 0 &&
            event.transit == false && (
              <div style={styles.flightDetails}>
                Layover:{" "}
                {calculateDuration(
                  event.arrival_date,
                  event.arrival_time,
                  layover_depart_date,
                  layover_depart_time,
                  event.offset_arrival,
                  layover_offset_depart
                )}
              </div>
            )}
          {event.type == "depart" &&
            layover_depart_date.length > 0 &&
            event.transit == false && (
              <hr style={{ borderTop: "1px dotted grey" }} />
            )}

          {event.type == "return" &&
            layover_return_date.length > 0 &&
            event.transit == false && (
              <hr style={{ borderTop: "1px dotted grey" }} />
            )}
          {event.type == "return" &&
            layover_return_date.length > 0 &&
            event.transit == false && (
              <div style={styles.flightDetails}>
                Layover:{" "}
                {calculateDuration(
                  event.arrival_date,
                  event.arrival_time,
                  layover_return_date,
                  layover_return_time,
                  event.offset_arrival,
                  layover_offset_return
                )}
              </div>
            )}
          {event.type == "return" &&
            layover_return_date.length > 0 &&
            event.transit == false && (
              <hr style={{ borderTop: "1px dotted grey" }} />
            )}
        </>
      );
    });
  };

  const calculateDuration = (
    startDate,
    startTime,
    endDate,
    endTime,
    offsetStart,
    offsetEnd
  ) => {
    const startDateTime = new Date(
      formatDate(startDate).getTime() +
        timeToMilliseconds(startTime) -
        offsetStart * 3600 * 1000
    );
    const endDateTime = new Date(
      formatDate(endDate).getTime() +
        timeToMilliseconds(endTime) -
        offsetEnd * 3600 * 1000
    );
    const duration = (endDateTime - startDateTime) / (1000 * 60); // Convert milliseconds to minutes and take absolute value
    return `${Math.floor(duration / 60)} hours ${Math.floor(
      duration % 60
    )} minutes`;
  };

  const timeToMilliseconds = (time) => {
    const hours = parseInt(time.substring(0, 2), 10);
    const minutes = parseInt(time.substring(2, 4), 10);
    return (hours * 3600 + minutes * 60) * 1000;
  };

  return (
    <div style={styles.timeline} id="timeline">
      <div style={styles.container}>
        <div style={styles.column}>
          <span style={styles.label}>Destination:</span>
          <span>{destination}</span>
        </div>
        <div style={styles.column}>
          <span style={styles.label}>PNR:</span>
          <span>{pnr}</span>
        </div>
        <a href={`https://trip.arbatravel.com/flight-timeline?tripid=${tripId}`} style={styles.column}>
          <QRCodeSVG
            value={`https://trip.arbatravel.com/flight-timeline?tripid=${tripId}`}
            title={"trip.arbatravel.com"}
            size={50}
            bgColor={"#333333"}
            fgColor={"#ffffff"}
            level={"L"}
            marginSize={3}
            imageSettings={{
              src: "",
              x: undefined,
              y: undefined,
              height: 24,
              width: 24,
              opacity: 1,
              excavate: false,
            }}
          />
        </a>
      </div>
      {renderFlightDetails()}
      {errors && <h3 style={{ color: "red" }}>{errors}</h3>}
      <hr style={{ borderTop: "1px solid #444" }} />
      <p style={styles.description}>
        Disclaimer: These flight details are tentative & subject to change until
        confirmed. Trip confirmation will be provided 14 days before departure.
      </p>
      <button style={styles.button} onClick={downloadPdf}>
        Download as PDF
      </button>
    </div>
  );
};

export default Home;
